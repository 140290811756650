.summaryContainer {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    border: 1px solid rgb(156, 154, 155);
}

.dateHolder {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
}

.titleHolder {
    border-bottom: 2px solid rgb(12, 119, 160);
    padding: 10px 0;
}

.titleHolder>span {
    background: rgb(12, 119, 160);
    padding: 11px;
    color: white;
}

.summaryTable {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    gap: 20px;
}

.branchTitleHolder {
    padding: 10px 0;
}

.branchTitle {
    background: rgb(156, 154, 155);
    padding: 11px;
    font-weight: bold;
}

#tabled {
    border-collapse: collapse;
    width: 100%;
}

#tabled td,
#tabled th {
    border: 1px solid rgb(156, 154, 155);
    padding: 5px 15px;
    text-align: center;
    color: rgb(116, 112, 111);
}

#tabled tr:nth-child(even) {
    background-color: rgb(212, 210, 210);
}

#tabled th {
    padding-top: 8px;
    padding-bottom: 8px;
    color: rgb(12, 119, 160);
}

.totalLabel {
    font-weight: bold;
}