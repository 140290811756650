.field-container {
    border: var(--border);
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    width: 50%;
    padding: 10px;
    margin: 10px;
    max-width: 400px;
    max-height: 120px;

}

.field-group {
    display: flex;
    flex-direction: row;
    /* border: var(--border); */
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
}

.field-label {
    font-family: roboto;
    font-size: small;
    font-weight: bold;
    width: 50%;

}

.field-value {
    border-radius: 2px;
    font-size: small;
    border: none;
    background-color: whitesmoke;
    color: black;
    font-family: roboto;
    margin-bottom: 10px;
    /* z-index: 1000 !important; */
    border: var(--border);
}

.field-sm {
    height: 20px;
    width: 40px;
}

.field-med {
    height: 20px;
    width: 80px;

}