  
  .react-autosuggest__container {
    /* display: flex; */
    position: relative;
    /* align-items: center; */
    width: 50%;
  }
  
  .react-autosuggest__input {
    /* display: block; */
    /* padding-left: 10px; */
    /* outline: none; */
    border-radius: 2px;
    height: 20px;
    width: 100%;
    border: none;
    background-color: whitesmoke;
    color:black;
    font-family: roboto;
    margin-bottom: 10px;
    /* z-index: 1000 !important; */
    border: var(--border);
    text-transform:uppercase;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    max-width: 400px;
    min-width: 80px;
    transform: translateY(-10px);
    /* top: 51px; */
    /* width: 200px; */
    border: 1px solid #aaa;
    background-color: #fff;
    /* font-family: Helvetica, sans-serif; */
    font-weight: 300;
    font-size: small;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    align-items: left;
    overflow-y: auto;
    max-height: 200px;
  }
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    /* list-style-type: none; */
    /* max-height: 200px;
    overflow-y: auto; */
}
  
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 10px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  
  .react-autosuggest__suggestion-match {
    color: red;
    font-weight: bold;
  }
  

  @media only screen and (max-width: 550px) {
    .react-autosuggest__input {
      width: 130px;
    }
  }