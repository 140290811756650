.mr-form-container {
  /* margin: 100px 100px 100px 100px; */
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  /* position: relative; */
  /* border-radius: 10px; */
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%); */
  background-color: var(--nav-bg);
  overflow-y: auto;
  filter: var(--filter);
  padding: 20px;
  border-radius: var(--border-radius);
  /* width: 100%; */
  /* border: var(--border); */
  /* overflow: auto; */
}

.mr-form-container .table-container {
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: flex-start;
  padding: 10px;
  border: solid black 2px;
  margin: 10px;
  background-color: rgba(236, 238, 236, 0.884);
  overflow-y: scroll;
  max-height: 200px;
  overflow-x: scroll;
}
