/* Loader.css */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh; 
}
.spinner {
  border: 6px solid #e3d6d6; 
  border-top: 6px solid #4ba8e6; 
  border-radius: 50%;
  width: 35px;
  height: 35px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}