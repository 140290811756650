.dash-grid-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  // grid-template-rows: 1fr 1fr 1fr;
  // gap: 25px 25px;
  // grid-template-areas:
  //     "card-1 card-2 card-3 card-4"
  //     "card-5 card-6 card-7 card-8"
  //     "card-9 card-10 card-11 card-12";
  //   ". . . .";
  // padding: 25px 25px 25px 25px;
  // background-color: var(--bg);
}

@for $i from 1 through 90 {
  .Card-#{$i} {
      // grid-area: card-#{$i};
      // border: 20px double #ccd3da;
      border-radius: var(--border-radius);
      // height: 150px;
      height: 45px;
      // width: 100px;
      // border: 20px double #e1e2e463;
      margin: 10px 0;
      cursor: pointer;
      background-color: #31323375;
      align-items: center;
      justify-content: center;
      display: flex;
      text-shadow: 1px 2px black;
      // -webkit-text-stroke: 0.5px black;

  }    
}

.btnfos-6 {
  // border: 0 solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
  // outline: 0px solid;
  // outline-color: rgba(255, 255, 255, 0);
  // outline-offset: 0px;
  // text-shadow: none;
  // -webkit-transition: all 250ms cubic-bezier(0.19, 1, 0.22, 1);
  //         transition: all 250ms cubic-bezier(0.19, 1, 0.22, 1);
  // outline-color: rgba(32, 29, 29, 0.5);
  // outline-offset: 0px;
}
.btnfos-6:focus,
.btnfos-6:hover {
  // border: 20px double black ;
  box-shadow: inset 0 0 20px rgba(34, 28, 28, 0.5), 0 0 20px rgba(17, 15, 15, 0.2);
  // outline: 1px solid;
  // outline-offset: 15px;
  // outline-color: rgba(255, 255, 255, 0);
  text-shadow: 1px 1px 2px #427388;

}


.Card-text{
  color: whitesmoke;
  // font-size: x-large;
  font-size: medium
} 

// resonsiiveness

@media only screen and (max-width: 960px) {
  .grid-container {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 1fr);
      gap: 25px 25px;
      grid-template-areas:
          "card-1 card-2"
          "card-3 card-4"
          "card-5 card-6"
          "card-7 card-8"
          "card-9 card-10"
          "card-11 card-12";
      }
}

@media only screen and (max-width: 460px) {
  .grid-container {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(12, 1fr);
      gap: 25px 25px;
      grid-template-areas:
          "card-1"
          "card-2"
          "card-3"
          "card-4"
          "card-5"
          "card-6"
          "card-7"
          "card-8"
          "card-9"
          "card-10"
          "card-11"
          "card-12";
      }
}