.titlebar {
  /* background-color:#23293e; */
  background-color:#23293e;
  /* height: var(--title-bar-size); */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  /* position: sticky; */
  top: 0;
  z-index: 999;
}

.block {
  display: block;
}

.hidden{
  display: none;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8rem;
}

.titlebar-container {
  /* display: flex; */
  /* flex-direction: row;
  justify-content: flex-start;
  align-items: center; */
  height: 80px;
  width: 100%;
  padding: 0 10px 0 10px;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn {
  background-color: #23293e;
  color: white;
  padding: 10px;
}

.titlebar-logo {
  background-color:#23293e;
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

@media only screen and (max-width: 960px) {
  .titlebar-container {
    display: block;
  }
  
  .logo {
    display: block;
    /* width: 500px; */
    margin: 0px auto;
  }

  .btn {
    display: block;
    width: 65px;
    margin: 5px auto;
  }

  .titlebar-logo {
    font-size: 1.9rem;
  }
}

@media only screen and (max-width: 460px) {
  .titlebar-logo {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 364px) {
  .titlebar-logo {
    font-size: 1.4rem;
  }
  
}

@media only screen and (max-width: 340px) {
  .titlebar-logo {
    font-size: 1.2rem;
  }
  
}