.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    background-color: var(--bg);
    height: 100vh;
}

.branch-selector-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    background-color: var(--bg);   
}

.branch-selector-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 30px;
    border: 3px solid black;
    border-radius: 5px;
    padding: 30px;
    /* align-items: center; */
    justify-content: center;
}

.branch-selector-header {
    font-size: 30px;
    font-weight: bold;
}


/* responsiveness */

@media only screen and (max-width: 960px) {
    .login-wrapper{
        margin-top: 42px;
    }
}

@media only screen and (max-width: 460px) {
    .login-wrapper{
        margin-top: 35px;
    }
}

@media only screen and (max-width: 364px) {
    .login-wrapper{
        margin-top: 32px;
    }

    .branch-selector-form {
        width: 200px;
    }
    
  }

  @media only screen and (max-width: 340px) {
    .login-wrapper{
        margin-top: 30px;
    }
    
  }