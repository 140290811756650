.charts-container {
  display: flex;
  justify-content: center; /* Centers the chart */
  align-items: center;     /* Vertically aligns the chart */
  flex-direction: row;
  flex-wrap: wrap;
}
.chart-card {
  background-color: #e4dcdc; 
  border-radius: 8px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px; 
  margin: 20px; 
  flex: 1 1 400px; 
  max-width: 600px;
  max-height: 600px; 
  height: auto; 
  display: block; 
  box-sizing: border-box; 
}
.chart-card h3 {
  text-align: center; 
  margin-bottom: 10px; 
  /* float: left; */
}
.chart-card canvas {
  max-width: 100% !important;
  max-height: 100% !important; 
}
