.form-container {
  /* margin: 100px 100px 100px 100px; */
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  /* position: relative; */
/* border-radius: 10px; */
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%); */
  background-color: var(--nav-bg);
  overflow-y: auto;
  filter: var(--filter);
  padding: 20px;
  border-radius: var(--border-radius);
  /* width: 100%; */
  /* border: var(--border); */
}

.close-btn {
  position: absolute;
  top: 2%;
  right: 3%;
  font-size: 1.5rem;
  z-index: 1;
  color: #fff;
  cursor: pointer;
}

.form-img {
  width: 80%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-img-2 {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-success {
  text-align: center;
  font-size: 24px;
  margin-top: 80px;
  color: #fff;
}

.form-header {
  display: flex;
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  /* width: 100%; */
  background-color: var(--form-bg);
  /* color: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%); */
  font-size: 2em;
  margin-bottom: 10px;
  /* filter: var(--filter); */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
  border-radius: var(--border-radius);
  /* margin-left: 10px;
  margin-right: 10px; */
  align-items: center;
  justify-content: center;
}

.form-header-bilty {
  display: flex;
  padding-left: 400px;
  padding-top: 2px;
  padding-bottom: 2px;
  /* width: 100%; */
  background-color: var(--form-bg);
  /* color: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%); */
  font-size: 2em;
  margin-bottom: 10px;
  /* filter: var(--filter); */
  /* box-shadow: 0 -2px 10px rgba(0, 0, 0, 1); */
  border-radius: var(--border-radius);
  /* margin-left: 10px;
  margin-right: 10px; */
  align-items: center;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  /* margin: 10px 10px 10px 10px; */
  background-color: var(--form-bg);
  /* filter: var(--filter); */
  border-radius: var(--border-radius);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
}

.form-title {
  margin-top: 2px;
  margin-left: 10px;
  margin-right: 80px;
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: row; */
}

.form-title-b {
  margin-top: 2px;
  margin-left: 10px;
  margin-right: 80px;
  /* width: 100%; */
  display: flex;
  /* padding-left: ; */
  /* justify-content: space-between; */
  align-items: center;
  /* flex-direction: row; */
}

.bill-input-small {
  width: 60px
}
.bill-input-medium {
  width: 80px
}
.bill-input-large {
  width: 210px
}

.bill-margin {
  padding-right: 10px;
  display: inline;
  margin-right: 2.5em;
}

.tds-margin {
  padding-left: 10px;
  display: inline;
  margin-right: 1em;
}

.flex-gap {
  display: flex;
  align-self: end;
  flex-direction: column;
  gap: 10px;
}
.flex-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.flex-box {
  display: flex;
  gap: 10px;
}

.form-input-content-block-0 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: solid black 2px;
  border-top: solid black 2px;
}
#bloc1, #bloc2
{
    display:inline;
}
.form-input-c {
  width: 100%;
  display: flex;
  /* justify-content: flex-start; */
  /* align-items: flex-start; */
  border-bottom: solid black 2px;
  border-top: solid black 2px;
}

.form-field-left-most {
  /* grid-column: 1 / 1; */
  /* border: solid rgb(226, 14, 14); */
  display: flex;
  flex-direction: column;
  border-width: 0 10px;
  justify-content: flex-start;
  align-items: left;
  width: 30%;
  padding: 10px 1px 10px 10px;
  /* border-right: solid black 2px; */
}

.form-field-left {
  /* grid-column: 1 / 1; */
  /* border: solid rgb(226, 14, 14); */
  display: flex;
  flex-direction: column;
  border-width: 0 10px;
  justify-content: flex-start;
  align-items: right;
  width: 50%;
  padding: 10px;
  /* border-right: solid black 2px;
  border-left: solid black 2px; */
}

.form-field-right {
  /* grid-column: 2 / 2; */
  display: flex;
  flex-direction: column;
  border-width: 0 10px;
  justify-content: flex-start;
  align-items: left;
  width: 50%;
  padding: 10px;
  border-right: solid black 2px;
  border-left: solid black 2px;
}

.checkbox-position {
  position: relative;
  top: 10px;
  right: 140px;
}

.checkbox {
  height: 18px;
  width: 18px;

}

.form-field-vertical {
  display: flex;
  flex-direction: column;
  border-width: 0 10px;
  justify-content: left;
  align-items: left;
  /* width: 50%; */
  padding: 10px;
  border-left: solid black 2px;
  /* border-bottom: solid black 2px; */
}

.form-field-vertical_wide {
  display: flex;
  flex-direction: column;
  border-width: 0 10px;
  justify-content: left;
  align-items: left;
  /* width: 50%; */
  padding: 10px 1px 10px 10px;
  border-left: solid black 2px;
  /* border-bottom: solid black 2px; */
}

.form-field-vertical_no_b {
  display: flex;
  flex-direction: column;
  border-width: 0 10px;
  justify-content: left;
  align-items: left;
  /* width: 50%; */
  padding: 10px 10px 10px 0px;
  /* border-left: solid black 2px; */
  /* border-bottom: solid black 2px; */
}

.form h1 {
  font-size: 1rem;
  text-align: start;
  width: 80%;
  margin-bottom: 1rem;
  color: #fff;
}

.form-row {
  /* margin-bottom: 0.5rem; */
  /* width: 100%; */
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}

.form-row-tds {
  /* margin-bottom: 0.5rem; */
  width: 70%;
  /* padding-r: 500px; */
  /* display: flex; */
  padding-right: 10px;
  display: inline;
  margin-right: 2.5em;
  /* justify-content: space-around; */
  /* align-items: center; */
}

.form-row-report {
  /* margin-bottom: 0.5rem; */
  /* width: 100%; */
  display: flex;
  /* width: 50px; */
  /* justify-content: space-around; */
  align-items: center;
}

.date-box {
  /* margin-bottom: 0.5rem; */
  /* width: 80%; */
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}

.form-row-m {
  /* margin-bottom: 0.5rem; */
  /* width: 100%; */
  padding-left: 10px;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}

.form-parent {
  display: flex;
  /* padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px; */
  /* width: 100%; */
  background-color: var(--form-bg);
  /* color: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%); */
  /* font-size: 2em; */
  margin-bottom: 10px;
  /* filter: var(--filter); */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
  border-radius: var(--border-radius);
  /* margin-left: 10px; */
  /* margin-right: 10px;  */
  align-items: center;
  justify-content: center;}

.form-side {
  /* margin-bottom: 0.5rem; */
  /* width: 100%; */
  /* flex-direction: row; */
  padding-left: 300px;
  font-size: 25px;
  /* padding-right: 10px; */
  display: flex;
  /* justify-content: space-around; */
  align-items:flex-end;
}

.clear-button {
  font-size: 15px;
  margin-right: 10px;
}

.form-al {
  /* margin-bottom: 0.5rem; */
  /* width: 100%; */
  /* flex-direction: row; */
  padding-left: 5px;
  padding-right: 3px;
  display: flex;
  font-weight: bold;
  font-size: 25px;
  /* justify-content: space-around; */
  align-items:center;
}

.border {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  /* width: 100%; */
  /* display: flex; */
  /* justify-content: space-around; */
  border-top: solid black 2px;;
}

.form-row p {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: #f00e0e;
  padding-left: 10px;
}

.form-label {
  /* display: inline-block; */
  min-width: 70px;
  width: 20%;
  margin-bottom: 6px;
  font-family: roboto;
  font-size: small;
  font-weight: bold;
  /* color: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%); */
}

.form-label-w {
  /* display: inline-block; */
  min-width: 90px;
  width: 20%;
  margin-bottom: 6px;
  font-family: roboto;
  font-size: small;
  font-weight: bold;
  /* color: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%); */
}

.form-last_bilty {
  /* display: inline-block; */
  min-width: 80px;
  width: 30%;
  margin-bottom: 6px;
  font-family: roboto;
  font-size: medium;
  font-weight: bold;
  /* color: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%); */
}

.form-pod_statement {
  /* display: inline-block; */
  min-width: 80px;
  padding-left: 20px;
  width: 50%;
  margin-bottom: 6px;
  font-family: roboto;
  font-size: medium;
  font-weight: bold;
  /* color: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%); */
}


.form-consignor_bill {
  /* display: inline-block; */
  min-width: 80px;
  padding-left: 20px;
  width: 50%;
  margin-bottom: 6px;
  font-family: roboto;
  font-size: medium;
  font-weight: bold;
  /* color: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%); */
}

.form-last_statement {
  /* display: inline-block; */
  padding-left: 20px;
  min-width: 80px;
  width: 30%;
  margin-bottom: 6px;
  font-family: roboto;
  font-size: medium;
  font-weight: bold;
  /* color: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%); */
}

.form-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  border-top: solid black 2px;
}

.form-input {
  /* display: block; */
  /* padding-left: 10px; */
  /* outline: none; */
  border-radius: 2px;
  height: 20px;
  width: 50%;
  border: none;
  background-color: whitesmoke;
  color: black;
  font-family: roboto;
  margin-bottom: 10px;
  /* z-index: 1000 !important; */
  border: var(--border);
  text-transform: uppercase;
}

.form-input-wide {
  /* display: block; */
  /* padding-left: 10px; */
  /* outline: none; */
  border-radius: 2px;
  height: 20px;
  width: 55px;
  border: none;
  background-color: whitesmoke;
  color: black;
  font-family: roboto;
  margin-bottom: 10px;
  margin-right: 20px;
  /* z-index: 1000 !important; */
  border: var(--border);
  text-transform: uppercase;
}

.form-input-tds {
  /* display: block; */
  /* padding-left: 10px; */
  /* outline: none; */
  border-radius: 2px;
  height: 20px;
  width: 20%;
  /* padding-right: 10%; */
  margin-right: 10px;
  border: none;
  background-color: whitesmoke;
  color: black;
  font-family: roboto;
  margin-bottom: 10px;
  /* z-index: 1000 !important; */
  border: var(--border);
  text-transform: uppercase;
}

.form-input-mr-statement-date {
  /* display: block; */
  /* padding-left: 10px; */
  /* outline: none; */
  border-radius: 2px;
  height: 20px;
  width: 130px;
  border: none;
  background-color: whitesmoke;
  color: black;
  font-family: roboto;
  margin-bottom: 10px;
  margin-right: 10px;
  /* z-index: 1000 !important; */
  border: var(--border);
  text-transform: uppercase;
}

.form-input-vehicle-date {
  /* display: block; */
  /* padding-left: 10px; */
  /* outline: none; */
  border-radius: 2px;
  height: 20px;
  width: 130px;
  border: none;
  background-color: whitesmoke;
  color: black;
  font-family: roboto;
  margin-bottom: 10px;
  /* z-index: 1000 !important; */
  border: var(--border);
  text-transform: uppercase;
}

.form-input-small {
  /* display: block; */
  /* padding-left: 10px; */
  /* outline: none; */
  border-radius: 2px;
  height: 20px;
  width: 50%;
  border: none;
  background-color: whitesmoke;
  color: black;
  font-family: roboto;
  margin-bottom: 10px;
  /* z-index: 1000 !important; */
  border: var(--border);
  text-transform: uppercase;
}

.form-input-suffix {
  /* display: block; */
  /* padding-left: 10px; */
  /* outline: none; */
  border-radius: 2px;
  height: 20px;
  width: 40px;
  border: none;
  background-color: whitesmoke;
  color: black;
  font-family: roboto;
  margin-bottom: 10px;
  /* z-index: 1000 !important; */
  border: var(--border);
  text-transform: uppercase;
}

.form-input-wc-dd {
  /* display: block; */
  /* padding-left: 10px; */
  /* outline: none; */
  border-radius: 2px;
  height: 20px;
  width: 80px;
  border: none;
  background-color: whitesmoke;
  color: black;
  font-family: roboto;
  margin-bottom: 10px;
  /* z-index: 1000 !important; */
  border: var(--border);
  text-transform: uppercase;
}

.form-input-wc-dd-big {
  /* display: block; */
  /* padding-left: 10px; */
  /* outline: none; */
  border-radius: 2px;
  height: 20px;
  width: 120px;
  border: none;
  background-color: whitesmoke;
  color: black;
  font-family: roboto;
  margin-bottom: 10px;
  /* z-index: 1000 !important; */
  border: var(--border);
  text-transform: uppercase;
}

.chform-input-suffix {
  /* display: block; */
  /* padding-left: 10px; */
  /* outline: none; */
  border-radius: 2px;
  height: 20px;
  width: 40px;
  border: none;
  background-color: whitesmoke;
  color: black;
  font-family: roboto;
  /* z-index: 1000 !important; */
  border: var(--border);
  text-transform: uppercase;
}

.chform-input-med {
  /* display: block; */
  /* padding-left: 10px; */
  /* outline: none; */
  border-radius: 2px;
  height: 20px;
  width: 65px;
  border: none;
  background-color: whitesmoke;
  color: black;
  font-family: roboto;
  /* z-index: 1000 !important; */
  border: var(--border);
  text-transform: uppercase;
}


.form-input::placeholder {
  color: black;
  font-size: 12px;
}

.form-input-btn {
  width: 80%;
  height: 20px;
  margin-top: 10px;
  border-radius: 2px;
  background: linear-gradient(
    90deg,
    rgb(39, 176, 255) 0%,
    rgb(0, 232, 236) 100%
  );
  outline: none;
  border: none;
  color: #fff;
  font-size: 1rem;
}

.form-input-btn:hover {
  cursor: pointer;
  background: linear-gradient(
    90deg,
    rgb(39, 143, 255) 0%,
    rgb(12, 99, 250) 100%
  );
  transition: all 0.4s ease-out;
}

.form-input-login {
  font-size: 0.8rem;
  margin-top: 10px;
  color: #fff;
  width: 80%;
  text-align: center;
}

.form-input-login a {
  text-decoration: none;
  color: #27cdff;
  font-weight: 600;
}

.table-container {
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: flex-start;
}

.bilty-inquiry-dynamic{
  width: 55%;
}

.bilty-inquiry-pod-image {
  margin-top: 35px;
  padding: 15px;
  /* height: 65%; */
  overflow: hidden;
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.bilty-pod-img {
  width: auto; 
  max-width: 100%; 
  max-height: 100%; 
  object-fit: contain; 
  height: 100vh;
}

.table-container_scroll {
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: flex-start;
  max-width: 1270px;
  overflow-x: scroll;
  overflow-y: scroll;
}

.type-bilty {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

/* .bilty-inquiry-date {
  margin-right: 600px;
} */

.narration-box {
  display: flex;
    /* flex-direction: column; */
    border-width: 0 10px;
    justify-content: flex-start;
    align-items: right;
    width: 50%;
    padding: 10px;
}

.narration-box input {
  margin-left: 30px;
  width: 40%;
}




/* responsiveness  */

@media only screen and (max-width: 700px) {

  .form-input-content-block-0{
    flex-direction: column;
  }

  .form-input-content-block-0 .form-field-left {
    border: none;
  }

  .form-title .form-row {
    width: 145px;
  }

  .form-title .form-row-m {
    width: 207px;
    margin-left: -7px;
  }

  .form-input-content-block-0 .form-field-left .form-row {
    width: 570px;
  }

  .form-input-content-block-0 .form-field-left .form-input {
    width: 300px;
  }

  .form-input-content-block-0 .form-field-left .form-input-s {
    width: 136px;
  }

  .form-input-content-block-0 .form-field-right {
    border: none;
  }

  .form-field-left-1 {
    border: none;
  }

  .form-input-content-block-0 .form-field-right .form-row {
    width: 570px;
  }

  .form-input-content-block-0 .form-field-right .form-input {
    width: 300px;
  }

  .form-input-content-block-0 .form-field-right .form-input-s {
    width: 136px;
  }


  /* for mrpending amount */
  .responsive-row {
    display: flex;
    flex-direction: column;
  }

  .responsive-row .date-label {
    margin-left: 91px;
  }

}

@media only screen and (max-width: 600px) {
  .form-input-content-block-0 .form-field-left .form-row {
    width: 380px;
  }

  .form-title .form-row {
    width: 165px;
  }

  .form-input-content-block-0 .form-field-left .form-label {
    width: 150px;
  }

  .form-input-content-block-0 .form-field-right .form-row {
    width: 380px;
  }

  .form-input-content-block-0 .form-field-right .form-label {
    width: 150px;
  }
}

@media only screen and (max-width: 550px) {
  .form-input-content-block-0 .form-field-left .form-row {
    width: 280px;
  }

  .form-title .form-row {
    width: 165px;
  }

  .form-input-content-block-0 .form-field-left .form-label {
    width: 90px;
  }

  .form-input-content-block-0 .form-field-right .form-row {
    width: 280px;
  }

  .form-input-content-block-0 .form-field-right .form-label {
    width: 90px;
  }

  #size {
    width: 100px;
  }

  #size2 {
    width: 120px;
  }
  #size3 {
    width: 200px;
  }
  .move {
    margin-left: 10px;
  }

  .move2 {
    margin-left: 80px;
  }
}


@media only screen and (max-width: 400px){
  .form-input-content-block-0 .form-field-left .form-row {
    width: 260px;
  }

  .form-title .form-row {
    width: 140px;
  }

  .form-input-vehicle-date {
    margin-left: -16px;
  }

  .form-input-content-block-0 .form-field-left .form-label {
    width: 90px;
  }

  .form-input-content-block-0 .form-field-right .form-row {
    width: 260px;
  }

  .form-input-content-block-0 .form-field-right .form-label {
    width: 90px;
  }
}

@media only screen and (max-width: 356px) {
  .form-input-content-block-0 .form-field-left .form-row {
    width: 230px;
  }
  
  .form-title .form-row {
    width: 140px;
  }


  .form-input-content-block-0 .form-field-left .form-label {
    width: 75px;
  }

  .form-input-content-block-0 .form-field-right .form-row {
    width: 230px;
  }

  .form-input-content-block-0 .form-field-right .form-label {
    width: 75px;
  }
}

@media only screen and (max-width: 318px) {
  .form-input-content-block-0 .form-field-left .form-row {
    width: 140px;
  }
  
  .form-title .form-row {
    width: 140px;
  }


  .form-input-content-block-0 .form-field-left .form-label {
    width: 55px;
  }

  .form-input-content-block-0 .form-field-right .form-row {
    width: 170px;
  }

  .form-input-content-block-0 .form-field-right .form-label {
    width: 55px;
  }
}

/* responsive */

@media only screen and (max-width: 900px) {
  .form-title  {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .form-last_bilty {
    width: 200px;
  }

  /* account report responsive date class */
  .responsive-date {
    display: flex;
  }

  /* account report responsive field class */
  .responsive-field {
    display: flex;
  }

  /* pod challan form */
  .type-bilty select{
    width: 40px;
  }

  /* challan inward */
  .inward-new-column-left {
    flex-direction: column;
  }

  .multi-table-container {
    flex-direction: column;
  }

}

@media only screen and (max-width: 690px) {
  .form-title  {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .form-last_bilty {
    width: 200px;
  }
}

@media only screen and (max-width: 378px) {
  .type-bilty input{
    width: 100px;
  }
}


/* making bilty responsive*/
@media only screen and (max-width: 1147px) {
  .responsive-table-container {
    flex-direction: column;
  }

  .responsive-table {
    width: auto;
    overflow-x: auto;
  }

  .form-side {
    font-size: 0.8rem;
  }
  .form-al {
    font-size: 0.8rem;
  }
  .form-parent .clear-button {
    font-size: 0.8rem;
  }
}


@media only screen and (max-width: 910px) {
  .amount-header {
    left: 230px;
  }
}

@media only screen and (max-width: 722px) {
  .amount-header {
    left: 130px;
  }
}

@media only screen and (max-width: 520px) {
  .amount-header {
    left: 50px;
  }

  #responsive-form-input {
    width: 100px;
  }
}

@media only screen and (max-width: 385px) {
  #responsive-form-input {
    width: 60px;
  }
}


/* applying media query in consignor billing */

@media only screen and (max-width: 955px) {
  .form-input-c {
    flex-direction: column;
  }

  .form-input-c .form-field-left {
    border: none;
  }
}

@media only screen and (max-width: 663px) {
  .extra-input-1 {
    width: 30px;
  }

  .extra-input-2 {
    width: 70px;
  }
}

@media only screen and (max-width: 577px) {
  .extra-input-1 {
    width: 60px;
  }

  .extra-input-2 {
    width: 150px;
  }
}

@media only screen and (max-width: 365px) {
  .form-input-w {
    width: 100px;
  }
}