.signup-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--form-bg);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
    padding: 10px; 
    border-radius: var(--border-radius);
}

.input-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px;
}

.item-1 {
    width: 40%;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
    border: 2px solid;
    min-height: 70 vh;
    padding: 10px;
}

.signup-form-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.item-2 {
    width: 20%;
    border: 2px solid;
    border-radius: var(--border-radius);
    padding: 10px;
}

.sub-item {
    /* display: flex;
    flex-wrap: wrap; */
    border: 1px solid;
    padding-top: 10px;
    /* align-items: center;
    align-content: space-evenly;
    justify-content: space-around; */
}

.item-3 {
    align-self: center;
}

.signup-button {
    margin: 10px;
}

.signup-form-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--nav-bg);
    overflow-y: auto;
    filter: var(--filter);
    padding: 20px;
    border-radius: var(--border-radius);
}

.form-input-box {
    padding: 10px;
    border-radius: 2px;
    /* width: 50%; */
    border: none;
    background-color: whitesmoke;
    color: black;
    font-family: roboto;
    /* margin-bottom: 10px; */
    /* z-index: 1000 !important; */
    border: var(--border);
    text-transform: uppercase;
}

.form-input-two {
    width: 50%;
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    margin-bottom: 10px;
    gap: 20px;
}

#signup-branch-name-div {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    max-height: 200px;
    margin-top: 10px;
}


/* responsiveness */

@media only screen and (max-width: 910px) {
    .input-wrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .item-1 {
        width: 500px;
        margin-bottom: 50px;
    }

    .item-2 {
        width: 500px;
        margin-bottom: 50px;
    }

    .item-3 {
        width: 500px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 634px) {
    .item-1 {
        width: 350px;
        margin-bottom: 50px;
    }

    .item-2 {
        width: 350px;
        margin-bottom: 50px;
    }

    .item-3 {
        width: 350px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 485px) {
    .item-1 {
        width: 250px;
        margin-bottom: 50px;
    }

    .item-1 .form-label {
        width: 46px;
    }

    .item-2 {
        width: 250px;
        margin-bottom: 50px;
    }

    .item-3 {
        width: 250px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 382px) {
    .item-1 {
        width: 200px;
        margin-bottom: 50px;
    }

    .item-1 .form-label {
        width: 46px;
    }

    .item-2 {
        width: 200px;
        margin-bottom: 50px;
    }

    .item-3 {
        width: 200px;
        margin-bottom: 30px;
    }
}