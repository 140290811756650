#party-belongs-label {
  z-index: 100;
  background-color: #d6d8d5;
}

#break {
  flex-basis: 100%;
  height: 0;
}

#party-belongs-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#party-belongs-radio {
  margin-top: -2%;
  padding: 5%;
  border-style: solid;
}

#party-belongs-to-branch-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5%;
}

#party-belongs-to-branch-label {
  z-index: 100;
  background-color: #d6d8d5;
}

#party-belongs-to-branch-check {
  margin-top: -2%;
  width: 100%;
  padding: 5%;
  border-style: solid;
  /* overflow: auto; */
}

#branch-name-div {
  /* max-height: 100px; */
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  max-height: 400px;
}

#party-belongs-checkbox {
  margin-top: 10px;
}

.challan-form-container .table-container-2{
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: flex-start;
  padding: 10px;
  border: solid black 2px;
  margin: 10px;
  background-color: rgba(236, 238, 236, 0.884);
  overflow-y: scroll;
  max-height: 200px;
  overflow-x: scroll;
}

.form-field-left-1 {
  /* grid-column: 1 / 1; */
  /* border: solid rgb(226, 14, 14); */
  display: flex;
  flex-direction: column;
  border-width: 0 10px;
  justify-content: flex-start;
  align-items: right;
  width: 50%;
  padding: 10px 0 0 10px;
  border-right: solid black 2px;
  /* border-left: solid black 2px; */
}


/* responsiveness */


@media only screen and (max-width: 700px) {
  .form-field-left-1 {
    border: none;
  }

  .form-field-left-1 .form-row {
    width: 570px;
  }

  .form-field-left-1 .form-input {
    width: 300px;
  }

  .form-field-right .table-container-2 {
    width: 510px;
  }

  .form-field-right #party-belongs-to-branch-div {
    width: 530px;
  }

}

@media only screen and (max-width: 600px) {
  .form-field-left-1 .form-row {
    width: 380px;
  }

  .form-field-left-1 .form-label-w {
    width: 150px;
  }

  .form-field-right .table-container-2 {
    width: 370px;
  }

  .form-field-right #party-belongs-to-branch-div {
    width: 390px;
  }
}

@media only screen and (max-width: 550px) {
  .form-field-left-1 .form-row {
    width: 280px;
  }

  .form-field-left-1 .form-label-w {
    width: 90px;
  }

  .form-field-right .table-container-2 {
    width: 280px;
  }

  .form-field-right #party-belongs-to-branch-div {
    width: 300px;
  }
}


@media only screen and (max-width: 400px){
  .form-field-left-1 .form-row {
    width: 260px;
  }

  .form-field-left-1 .form-label-w {
    width: 90px;
  }

  .form-field-right .table-container-2 {
    width: 240px;
  }

  .form-field-right #party-belongs-to-branch-div {
    width: 260px;
  }
}

@media only screen and (max-width: 356px) {
  .form-field-left-1 .form-row {
    width: 230px;
  }

  .form-field-left-1 .form-label-w {
    width: 75px;
  }

  .form-field-right .table-container-2 {
    width: 210px;
  }

  .form-field-right #party-belongs-to-branch-div {
    width: 250px;
  }
}

@media only screen and (max-width: 318px) {
  .form-field-left-1 .form-row {
    width: 140px;
  }

  .form-field-left-1 .form-label-w {
    width: 55px;
  }

  .form-field-right .table-container-2 {
    width: 180px;
  }

  .form-field-right #party-belongs-to-branch-div {
    width: 200px;
  }
}