.main-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--form-bg);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
    padding: 10px; 
    border-radius: var(--border-radius);
    align-items: center;
}

.role-wrapper {
    margin: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.name-wrapper {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;    
    /* margin: 10px; */
    margin-top: 10px;
    padding-bottom: 20px;
    /* margin-left: 10px; */
    align-items: space-around;
    justify-content: center;
    align-items: space-around;
    max-height: 250px;
    overflow:scroll;
    overflow-x: hidden;
    /* border: 2px solid; */
    /* border-radius: var(--border-radius); */
    /* background-color: white; */
    /* width: 40%; */
}

.text-style {
    /* display: inline-block; */
    min-width: 70px;
    width: 20%;
    font-family: roboto;
    font-size: small;
    font-weight: bold;
}

.select-style {
    width: 100%;
}

.edit-role-input-wrapper {
    display: flex;
    width: 100%;
    align-items: space-around; 
    justify-content: space-around;
    /* border: 2px solid; */    
    border-radius: var(--border-radius);
    padding: 10px;
}

.my-form-label {
    width: 100%;
}

.role-item {
    width: 40%;
    border: 2px solid;
    padding: 10px;
    border-radius: var(--border-radius);
}

.each-name {
    border: 1px solid;
    padding: 5px;
    margin: 5px;
    border-radius: var(--border-radius);
    background-color: white;
}


.button-item {
    margin: 10px;
}

#edit-role-branch-name-div {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
    max-height: 200px;
    margin-top: 10px;
}



/* responsiveness manage dashboard */

@media only screen and (max-width: 600px) {
    .edit-role-input-wrapper {
      flex-direction: column;
    }
  
    .role-item {
      width: 400px;
    }
  }

  @media only screen and (max-width: 519px) {
    .edit-role-input-wrapper {
      flex-direction: column;
    }
  
    .role-item {
      width: 300px;
    }
  }

  @media only screen and (max-width: 422px) {
    .edit-role-input-wrapper {
      flex-direction: column;
    }
  
    .role-item {
      width: 200px;
    }

    .role-wrapper {
        width: 200px;
        align-items: flex-start;
    }

    .role-wrapper input {
        width: 100px;
    }
  }

  @media only screen and (max-width: 322px) {
    .edit-role-input-wrapper {
      flex-direction: column;
    }
  
    .role-item {
      width: 150px;
    }
  }