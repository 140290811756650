.pop-up-containerr {
  background-color: var(--bg);
  border: black double 5px;
  border-radius: var(--border-radius);
  padding: 15px;
  max-height: 300px;
  width: 70%;
  overflow: scroll;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: block;
}

.pop-up-header {
  background-color: var(--bg);
  padding-bottom: 10px;
  border-bottom: black double 3px;
  font-size: larger;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cp-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: inherit;
  background-color: var(--form-bg);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
  padding: 20px;
  gap: 10px;
  border-radius: var(--border-radius);
}

.cp-form-label {
  /* display: inline-block; */
  min-width: 70px;
  width: 50%;
  margin-bottom: 6px;
  font-family: roboto;
  font-size: small;
  font-weight: bold;
}

.autobilling-wrapper {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: inherit;
  background-color: var(--form-bg);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
  padding: 20px;
  gap: 10px;
  border-radius: var(--border-radius);
}

/* Overlay for the popup */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: block;
  z-index: 999;
}

/* responsivess */

@media only screen and (max-width: 355px) {
}
