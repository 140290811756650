@page {
  size: A4;
  margin: 10px !important;
  /* padding: 10px; */
}

#table_div {
  /* margin-left: -1.8%; */
  margin-top: 3%;
  margin-right: -12%;
  border: 1px solid black;
  /* width: 100%; */
}
#details {
  display: flex;
  margin-bottom: 15px;
}

#details_left {
  padding-left: 1%;
  border: 0px;
  border-top: solid black 1px;
  border-bottom: solid black 1px;
  /* border-style: solid; */
  width: 33%;
}

#details_middle {
  padding-left: 1%;
  border: solid black 1px;
  width: 33%;
}

#details_right {
  padding-left: 1%;
  border: 0px;
  border-top: solid black 1px;
  border-bottom: solid black 1px;
  width: 33%;
}

#page_total_div {
  border: 0px;
  border-top: 1px;
  border-style: dotted;
  padding-top: 8px;
  padding-bottom: 8px;
}

#page_total_amount {
  margin-left: 10%;
}

#total_packs {
  margin-left: 38%;
}

#total_weight {
  margin-left: 8%;
}

#total_to_pay {
  margin-left: 3%;
}

#total_paid {
  margin-left: 2%;
}

#grand_total_div {
  border: 0px;
  border-top: 1px;
  border-style: dotted;
  padding-top: 8px;
  padding-bottom: 8px;
}

#grand_total_amount {
  margin-left: 8.8%;
}

#gtotal_packs {
  margin-left: 38%;
}

#gtotal_weight {
  margin-left: 8%;
}

#gtotal_to_pay {
  margin-left: 3%;
}

#gtotal_paid {
  margin-left: 2%;
}

#drivers_row_div {
  border: 0px;
  border-top: 1px;
  border-style: dotted;
  padding-top: 8px;
}

#rcc_label {
  margin-left: 50%;
  font-weight: bolder;
}

#signature_print_div {
  border: 0px;
  margin-top: 30px;
  padding-top: 8px;
  margin-bottom: 30px;
}

#print_label {
  margin-left: 30%;
}

#signature_print_details_div {
  margin-bottom: 10px;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
