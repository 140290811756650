
.dash {
  /* background-image: url("assets/images/truck.png"); */
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--bg);
  height: 100vh;
  width: 100%;
  /* overflow-y:initial; */
  /* overflow: hidden; */
}

.left-sidebar1 {
  width: 12%;
  background-color: var(--bg);
  padding: 0 10px;
  border-right: 1px solid gray;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  
}

.dash-content {
  flex: 1;
  padding: 15px;
  overflow-y: scroll;
  scrollbar-width: none;
  
}
.dash-content::-webkit-scrollbar {
  display: none;
}

.dash-top-title{
  width: 100%;
  float: left;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: gray;
}

.ttle{
  background-color: gray;
  float: left;
  padding-top: 2px;
  padding-right: 1%;
  padding-bottom: 0;
  padding-left: 1%;
}

.ttle-text{
  color: #FFFFFF;
  float: left;
  font-size: 16px;
  line-height: 36px;
  font-size: medium;
}

.dash-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px; 
  margin-bottom: 20px;
  box-sizing: border-box;
  margin-top: 50px;
}

.dash-table-content {
  width: 100%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  padding: 15px;
  border-radius: 8px;
  box-sizing: border-box;
  
  max-height: 400px;
  overflow-y: auto; 
  
}

.dash-notification-holder {
  background-color: #c1adad;
  padding: 20px;
  color: red;
  position: relative; 
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 5px;
}

.dash-notification-bar {
  padding: 10px;
  position: absolute; 
  top: 0;
  left: 100%;   display: flex;
  gap: 20px;
  animation: floatLeft 20s linear infinite;
}

.dash-notification-bar:hover {
  animation-play-state: paused;
}

@keyframes floatLeft {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}


/* responsiveness */


@media only screen and (max-width: 1200px) {
  .left-sidebar1 {
    width: 15%;
  }

  .dash-table {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 960px) {
  .left-sidebar1 {
    width: 17%;
  }

  .dash-content {
    padding: 10px;
  }

  .dash-table {
    grid-template-columns: 1fr;
    /* margin-top: 30px; */
  }
}

@media only screen and (max-width: 768px) {
  .left-sidebar1 {
    width: 17%;
  }

  .dash-content {
    padding: 10px;
    /* display: none; */
  }

  .dash-top-title {
    font-size: 14px;
  }

  .ttle-text {
    font-size: 14px;
  }

  .dash-table {
    margin-top: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .left-sidebar1 {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid gray;
  }

  .dash-content {
    display: none;
  }

  .dash-table {
    grid-template-columns: 1fr;
    margin-top: 20px;
  }

}
