
  
  .chform-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .chform-label{
    min-width: 70px;
    /* width: 10%; */
    margin-bottom: 6px;
    font-family: roboto;
    font-size: small;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .chform-input{
  
  }

  
  /* reponsive for party rate master */
@media only screen and (max-width: 900px) {
  .chform-row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .chform-label {
    width: 150px;
  }

  .chform-input {
    width: 269px;
  }

  .chform-input-a {
    width: 525px;
  }

  .form-input-wc-dd {
    width: 269px;
  }

  .form-input-wc-dd-big {
    width: 269px;
  }

  .chform-input-med {
    width: 269px;
  }
}

@media only screen and (max-width: 700px) {

  .chform-label {
    width: 130px;
  }

  .chform-input {
    width: 200px;
  }

  .chform-input-a {
    width: 400px;
  }

  .form-input-wc-dd {
    width: 200px;
  }

  .form-input-wc-dd-big {
    width: 200px;
  }

  .chform-input-med {
    width: 200px;
  }
}


/* responsiveness */
@media only screen and (max-width: 560px) {

  .chform-label {
    width: 100px;
  }

  .chform-input {
    width: 149px;
  }

  .chform-input-a {
    width: 219px;
  }

  .form-input-wc-dd {
    width: 149px;
  }

  .form-input-wc-dd-big {
    width: 149px;
  }

  .chform-input-med {
    width: 149px;
  }
}