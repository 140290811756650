/* <nav> */
.navbar {
  height: var(--nav-size);
  position: sticky;
  background-color: var(--nav-bg);
  padding: 0 10px;
  /* width: 100vw; */
  /* border-bottom: var(--border); */
  top: var(--title-bar-size);
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-content: bottom; */
}

.log-out-container {
  display: flex;
  justify-content: center;
  align-content: center;
  color: white;
  background-color: #23293e;
  padding: 7px;
  margin: 7px;
  transition: all 0.3s ease-out;
  /* border: black solid 3px; */
}
.log-out-container:hover, 
.log-out-container:focus {
  background-color: #474a55ad;
  /* color: black; */
  /* border: grey double 3px; */
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
}

.navbar-branch {
  display: flex;
  align-items: center;
  justify-content: center;
  color: lemonchiffon;
  font-size: large;
  border: rgb(59 91 94) double 3px;
  padding: 0 15px 0 15px;
}


.navbar2 {
  display: none;
}
.navbar3 {
  display: block;
  padding-top: 50px;
}

.nav-additional {
  display: flex;
  justify-content: space-between;
}

.nav-additional2 div{
  margin: 30px;
  border: none;
}

.navbar-nav2 {
  display: block;
  width: 11%;
  margin: 0 auto;
}

.log-out-container2 {
  display: block;
  width: 8%;
  margin: 5px auto;
  color: white;
  background-color: #23293e;
  padding: 7px;
}

.mobile-nav {
  height: 50px;
  width: 100%;
  background-color: var(--nav-bg);
  display: none;
}

.mobile-nav-height {
  height: 100%;
  width: 100%;
  background-color: var(--nav-bg);
  display: none;
}


.optional {
  color: white;
  font-weight: 700;
  font-size: 1.1rem;
  display: none;
  position: absolute;
  top: 85px;
  right: 30px;
}

.hide {
  display: none;
}


/* media query */

@media only screen and (max-width: 1320px) {
  .nav-additional {
    display: none;
  }

  .optional {
    display: inline;
  }
  .navbar {
    display: none;
  }
  .mobile-nav{
    display: block;
  }
  .mobile-nav-height {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .navbar {
    display: none;
  }
  .mobile-nav{
    display: block;
    margin-top: 40px;
  }

  .optional {
    top: 134px;
  }

  .navbar-nav2 {
    width: 15%;
  }

  .navbar3 {
    padding-top: 100px;
  }
  .log-out-container{
    margin: 0;
  }
}

@media only screen and (max-width: 620px) {
  .navbar-nav2 {
    width: 24%;
  }
}

@media only screen and (max-width: 460px) {
  .navbar-nav2 {
    width: 30%;
  }
  .log-out-container{
    margin: 0;
  }
}

@media only screen and (max-width: 364px) {
  .navbar-nav2 {
    width: 35%;
  }

  .optional {
    font-size: 0.8rem;
  }
}