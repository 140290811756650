@page {
  size: A4;
  margin: unset;
}

:root {
  --lines: 0;
}

#master {
  display: flex;
  border-style: var(--lines);
  flex-direction: column;
}
#main {
  border: 1px;
  height: 338.47px;
  width: 748.39px;
  margin-top: 17.5px;
  margin-left: 21px;
}

#main1 {
  border: 1px;
  height: 338.47px;
  width: 748.39px;
  margin-top: 35.5px;
  margin-left: 21px;
}

#main2 {
  border: 1px;
  border-style: var(--lines);
  height: 338.47px;
  width: 748.39px;
  margin-top: 35.5px;
  margin-left: 21px;
}

#row1 {
  display: flex;
  border: 1px;
  border-style: var(--lines);
  height: 33.33%;
  width: auto;
}

#r1col1 {
  border-top: 0px;
  border-left: 0px;
  border-right: 1px;
  border-bottom: 1px;
  border-style: var(--lines);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 74.61%;
}
#title {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px;
  border-style: var(--lines);
  margin-top: 1.5%;
  height: 40%;
  width: 100%;
}

#consign-details {
  display: flex;
  /* margin-top: 10% !important; */
}

#consign-details1 {
  display: flex;
  flex-direction: column;
  border-top: 1px;
  border-left: 1px;
  border-right: 1px;
  border-bottom: 1px;
  border-style: var(--lines);
  height: 100%;
  width: 55%;
  font-size: 90%;
  margin-left: 20%;
  margin-top: -1%;
}

#consign-details2 {
  display: flex;
  flex-direction: column;
  margin-top: -1%;
  border-top: 1px;
  padding-left: 10px;
  border-left: 1px;
  border-right: 1px;
  border-bottom: 1px;
  border-style: var(--lines);
  height: 100%;
  width: 40%;
  font-size: 90%;
}

#consignor {
  /* height: 10%;
      width: 20%; */
  /* margin-top: 0.5%; */
  /* margin-left: 20%; */
  /* padding-right: 10px; */
  overflow: hidden;
  white-space: nowrap;
}

#consignorGst {
  /* margin-top: 0.5%; */
  /* height: 10%;
      width: 20%; */
  /* border-style: var(--lines); */
  /* margin-left: 20%; */
}
#consigneeGst {
  padding-top: 3%;
  /* margin-top: 0.5%; */
  /* height: 10%;
      width: 20%; */
  /* border-style: var(--lines); */
  /* margin-left: 20%; */
}

#consignee {
  overflow: hidden;
  white-space: nowrap;
  padding-top: 3%;
  /* margin-top: 1.5%; */
  /* height: 10%;
      width: 20%; */
  /* border-style: var(--lines); */
  /* margin-left: 20%; */
}

#consignment-note {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px;
  /* border-color: black; */
  margin-top: 0%;
  border-style: var(--lines);
  height: 100%;
  width: 30%;
  font-size: 100%;
}

#no-date {
  display: flex;
  flex-direction: column;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px;

  height: 56.66%;
  border-style: var(--lines);
}

#consignment-note-no-label {
  font-weight: bolder;
  margin-top: 10%;
  margin-left: 35%;
  /* font-size: 50%; */
}

#consignment-note-date-label {
  margin-top: 2%;
  margin-left: 35%;
  /* font-size: 50%; */
}

#from {
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px;

  height: 21.67%;
  border-style: var(--lines);
}

#consignment-note-from-label {
  margin-top: 1.5%;
  margin-left: 35%;
  /* font-size: 50%; */
}

#to {
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  font-weight: bolder;
  height: 21.67%;
  border-style: var(--lines);
}

#consignment-note-to-label {
  margin-top: 1.5%;
  margin-left: 35%;
  /* font-size: 50%; */
}

#row2 {
  display: flex;
  border-top: 0px;
  border-left: 1px;
  border-right: 1px;
  border-bottom: 1px;
  border-style: var(--lines);
  height: 24.44%;
  font-size: 70%;
}

#no-of-articles {
  display: flex;
  border-top: 1px;
  border-left: 1px;
  border-right: 1px;
  border-bottom: 1px;
  border-style: var(--lines);
  /* border-style: var(--lines); */
  height: 100%;
  width: 8.12%;
  align-items: center;
}

#no-of-articles-label {
  /* height: auto; */
  /* /* margin-top: 50%; */
  font-size: 150%;
  font-weight: bolder;
  margin: auto;
  /* border-style: var(--lines) */
}

#description {
  display: flex;
  font-size: 130%;
  border-top: 0px;
  border-left: 0px;
  border-right: 1px;
  border-bottom: 0px;
  border-style: var(--lines);
  /* border-style: var(--lines); */
  height: 100%;
  width: 43.14%;
}

#description-label {
  margin-top: 7%;
  margin-left: 4%;
  width: 75%;
}

#d_remarks {
  margin-top: 8%;
}

#watermark {
  margin-left: 8%;
  margin-top: 4%;
  font-size: 400%;
}

#weight {
  font-size: 130%;
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 1px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  width: 13.18%;
}

#app {
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 1px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  width: 70%;
  align-items: center;
}

#app-label {
  margin: auto;
  /* margin-left: 2%; */
}

#ch {
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  width: 50%;
}

#ch-label {
  margin: auto;
}

#rate {
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 1px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  width: 7.59%;
}

#rate-label {
  margin: auto;
  font-size: 130%;
}

#freight {
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 1px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  width: 18.27%;
}

#to-pay {
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 1px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  width: 50%;
}

#to-pay-label {
  margin: auto;
  font-size: 130%;
}

#paid-bill {
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  width: 50%;
  /* align-content: center */
}

#paid-bill-label {
  margin: auto;
  font-size: 130%;
}

#remarks {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  /* width: 5%; */
}

#row3 {
  display: flex;
  border-top: 0px;
  border-left: 1px;
  border-right: 1px;
  border-bottom: 1px;
  border-style: var(--lines);
  height: 33.33%;
  font-size: 90%;
}

#row3col1 {
  border-top: 0px;
  border-left: 0px;
  border-right: 1px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  width: 59.39%;
}

#row3col1row1 {
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px;
  border-style: var(--lines);
  height: 70%;
  width: 100%;
}

#owner-risk {
  display: flex;
  flex-direction: column;
  border-top: 0px;
  border-left: 0px;
  border-right: 1px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  width: 90%;
}

#owner-risk-row1 {
  display: flex;
  height: 20%;
  width: 90%;
  /* border-style: solid; */
  margin-top: 1%;
  margin-left: 18%;
}

#owner-risk-row2 {
  height: 20%;
  width: 80%;
  /* border-style: solid; */
  display: flex;
  margin-top: 12%;
  margin-left: 18%;
}

#owner-risk-private-mark-div {
  /* border-style: solid; */
  font-weight: bold;
  height: auto;
  width: auto;
  font-size: 80%;
  margin-top: 3%;
  margin-left: 5%;
}

#owner-risk-delivery-on-div {
  /* font-size: 80%; */
  /* border-style: solid; */
  /* margin-top: 25%; */
  margin-left: 27%;
}

#owner-risk-value-rs-div {
  /* font-size: 80%; */
  margin-top: 2%;
  margin-left: 0.5%;
}

#owner-risk-mr-no-div {
  /* font-size: 80%; */
  /* border-style: var(--lines); */
  /* margin-top: 25%; */
  margin-left: 45%;
}

#consignor-copy {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  width: 40%;
}

#cc1 {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px;
  border-style: var(--lines);
  height: 50%;
  width: 100%;
}

#cc2 {
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 50%;
  width: 100%;

  align-items: center;
}

#cc2-label {
  font-weight: bolder;
  margin-left: 1%;
  font-size: 200%;
}

#pan-no {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 30%;
  width: 100%;
}

#total {
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 1px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  width: 13.19%;
}

#total-label {
  margin-top: 60%;
  margin-left: 40%;
}

#total-details {
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  /* align-items: center; */
  width: 26.9%;
}

#additional-charges {
  height: 80%;
  width: 10%;
  margin-left: 3%;
  margin-top: 5.5%;
  border-style: var(--lines);
}

#total-amount {
  margin-top: 90%;
  width: 100%;
  font-weight: bolder;
  border-style: var(--lines);
}
#total-details-label {
  /* border-style: var(--lines); */
  margin: auto;
  /* width: 90%; */
}

#row4 {
  display: flex;
  border-top: 0px;
  border-left: 1px;
  border-right: 1px;
  border-bottom: 1px;
  border-style: var(--lines);
  height: 8.9%;
  /* margin-left: 100px; */
  /* width: 100%; */
}


#delivery-at {
  display: flex;
  border-top: 0px;
  border-left: 0px;
  border-right: 1px;
  border-bottom: 0px;
  border-style: var(--lines);
  margin-top: 1%;
  height: 100%;
  width: 39.08%;
}

#delivery-at-display {
  display: flex;
  flex-direction: column;
}

#delivery-at-label {
  margin-top: 1%;
  margin-left: 35%;
}

#delivery-at-right {
  border-top: 0px;
  border-left: 0px;
  border-right: 1px;
  border-bottom: 0px;
  border-style: var(--lines);
  height: 100%;
  width: 18.78%;
}

#delivery-at-right-label {
  margin-left: 15%;
}

#owner-gstin-number {
  margin-left: 30px;
  font-weight: 700;
  margin-bottom: 5px;
}


#booking-officer {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: var(--lines);
  margin-left: 10%;
  margin-top: 1%;
  height: 100%;
  width: 40.1%;
}
