.pop-up-container {
    background-color: var(--bg);
    border: black double 5px;
    border-radius: var(--border-radius);
    padding: 15px;
    max-height: 500px;
    overflow: scroll;
    position: relative; 
}

.rr-table {
    max-height: 500px;
    overflow: scroll;
}

.pop-up-header {
    /* margin: 15px; */
    background-color: var(--bg);
    padding-bottom: 10px;
    border-bottom: black double 3px;
    /* padding: 5px; */
    font-size: larger;
    font-weight: bolder;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.pod-img-container {
    width: 100%;
    height: 100%;
    /* overflow: hidden;  */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pod-img {
    width: 55%; 
    height: 100%;
    /* transform: rotate(90deg);  */
    object-fit: contain;
    transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
    transform-origin: center center;
}

.pop-up-content {
    padding: 15px;
    font-size: medium;
    border-bottom: black double 3px;
    overflow-y: auto;
    flex-grow: 1;
}
.pop-up-close {
    color: black;
    cursor: pointer;
}

/* .pop-up-button:hover,
.pop-up-close:hover {
    transition: all 0.3s ease-out;
} */

.pop-up-fields {
    /* border: var(--border); */
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    /* background-color: gold; */
    /* width: 50%; */
    /* padding: 10px; */
    /* max-width: 300px; */
    padding: 10px 30px 10px 30px;
    

}
.pop-up-field {
    display: flex;
    flex-direction: row;
    /* border: var(--border); */
    align-items: flex-start;
    justify-content: space-evenly;
    padding-left: 5px;
    padding-right: 5px;
    
}

.pop-up-field-label {
    font-family: roboto;
    font-size: medium;
    font-weight: bold;
    /* width: 50%; */

}

.pop-up-field-value {
    border-radius: 2px;
    font-size: medium;
    border: none;
    /* background-color: whitesmoke; */
    color: black;
    font-family: roboto;
    /* margin-bottom: 10px; */
    /* z-index: 1000 !important; */
    /* border: var(--border); */
}
.pop-up-actions {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky; 
    bottom: 0px;
    width: 100%; 
    background-color: var(--bg);
}
.rr-pop-up-actions {
    padding-top: 10px;
    /* margin: 5px; */
    display: flex;
    align-items: center;
    justify-content: left;
}

.pop-up-button {
    padding: 5px 5px;
    /* background-color: var(--btn-bg);
    color: var(--btn-text-color); */
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin: 0 5px;
}

.pop-up-button:hover {
    background-color: var(--btn-hover-bg);
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
}
  
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.error-message {
    text-align: center;
    color: red;
    font-size: 16px;
    font-weight: bold;
    padding: 20px;
}
  