.page-addPhoto {
  background-color: var(--bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
.camera-size {
  width: 100%;
  height: auto; 
  object-fit: cover; 
}
.captured-size {
  max-width: 100%;
  height: auto;
  object-fit: contain; 
}
.capture-area {
  position: relative;
  display: flex;
  justify-content: center;
}
.capture-btn {
  position: absolute;
  z-index: 1;
  gap: 1rem;
  display: flex;
  bottom: 5rem;
  height: 30px;
}
.capture-btn-names{
  font-size: small;
  transition: transform 0.3s ease;
}
.capture-btn-names:hover {
  transform: scale(1.05); 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.pod-title{
  padding: 10px 0;
  font-size: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: aquamarine;
  border-radius: 5px;
  margin-bottom: 1rem;
}


@media only screen and (max-width: 768px) {
  .capture-area {
    width: 90%;
  }

  .capture-btn{
    gap: 5px;
    bottom: -2.5rem;
  }

}


