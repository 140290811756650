#main_header {
  text-align: center;
}

#location {
  margin-top: 2%;
  margin-bottom: 2%;
}

#address {
  margin-bottom: 1%;
}

#phone {
  margin-bottom: 1%;
}
