.report-challan {
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
    /* position: relative; */
    /* border-radius: 10px; */
    display: flex;
    flex-direction: column;
    /* background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%); */
    background-color: var(--nav-bg);
    overflow-y: auto;
    filter: var(--filter);
    padding: 20px;
    border-radius: var(--border-radius);
}

.report-challan-table-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--form-bg);
    /* filter: var(--filter); */
    border-radius: var(--border-radius);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
    width: 100%;
    padding: 20px 0 20px 0;
}