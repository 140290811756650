.bc-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin: 7px;
    overflow: auto;
}

/* responsiveness */

@media only screen and (max-width: 550px) {
    .form-label {
        width: 120px;
    }

    .form-label-w {
        width: 120px;
    }
}