/* <li> */
.nav-item {
    width: 140px;
    height: var(--nav-size);
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0 0.2em; */
  
  }
.nav-item-button {
    height: var(--nav-size);
    display: flex;
    align-items: center;
    justify-content: center;
    /* width:800px; */
    padding: 10px;
    /* transition: filter 300ms; */

  }


  
.nav-item-text {
    
    /* font-size: unset; */
    color: whitesmoke;
    display: block;
    font-size: 0.9em;
    
}