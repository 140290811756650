.challan-inward-form-container {
  /* margin: 100px 100px 100px 100px; */
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  /* position: relative; */
  /* border-radius: 10px; */
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%); */
  background-color: var(--nav-bg);
  /* overflow-y: auto; */
  filter: var(--filter);
  padding: 20px;
  border-radius: var(--border-radius);
  /* width: 100%; */
  /* border: var(--border); */
  /* overflow: auto; */
}

.challan-inward-form-container .table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: solid black 2px;
  margin: 10px;
  background-color: rgba(236, 238, 236, 0.884);
  /* overflow-y: scroll; */
  /* max-height: 200px; */
  /* overflow-x: scroll; */
}

.chform-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.chform-label {
  min-width: 70px;
  /* width: 10%; */
  margin-bottom: 6px;
  font-family: roboto;
  font-size: small;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chform-input {
}
