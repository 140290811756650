.pl-report-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.pl-report-left {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    width: 40%;
    gap: 10px;
}

.pl-report-inp {
    /* display: block; */
    /* padding-left: 10px; */
    /* outline: none; */
    /* border-radius: 2px; */
    height: 20px;
    width: 90%;
    border: none;
    background-color: whitesmoke;
    color: black;
    font-family: roboto;
    margin-bottom: 10px;
    /* z-index: 1000 !important; */
    border: var(--border);
    text-transform: uppercase;
}

.pl-report-right {
    width: 40%;
    margin: 10px;
    display: flex;
    flex-direction: row;
    /* align-items: center;
    justify-content: center; */
    /* max-height: 300px; */
    /* overflow: auto; */
}


/* responsiveness */

@media only screen and (max-width: 662px) {
    .pl-report-wrapper {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .pl-report-right {
        display: block;
        width: 450px;
    }

    .pl-report-left {
        width: 400px;
        margin-left: 15px;
    }
}

@media only screen and (max-width: 473px) {
    .pl-report-right {
        display: block;
        width: 350px;
    }

    .pl-report-left {
        width: 300px;
    }
}

@media only screen and (max-width: 380px) {
    .pl-report-right {
        display: block;
        width: 250px;
    }

    .pl-report-left {
        width: 200px;
    }
}
